import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { navigate } from "@reach/router"

import FormHeader from "./FormHeader"
import FormStep1 from "./FormSteps/FormStep1"
import FormStep2 from "./FormSteps/FormStep2"
import FormStep3 from "./FormSteps/FormStep3"
import FormStep4 from "./FormSteps/FormStep4"
import { CustomModal } from "../CustomModal"

const FormBodyMainDiv = styled.div`
  width: 100%;
  background-color: #f6f9fb;
  align-items: center;
  padding-bottom: 2rem;
  @media (min-width: 530px) {
    padding-bottom: 5rem;
  }
`

const FormBodyDiv = styled.div`
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* Borrar border si no es necesario*/
  /*
  border-left: 1px black solid;
  border-bottom: 1px black solid;
  border-right: 1px black solid;*/
`

export default function FormContainer(props) {
  const [formActived, setFormActived] = useState(1)

  //campos para envío de información de postulante
  const [name, setName] = useState("")
  const [lastName, setLastName] = useState("")
  const [lastName2, setLastName2] = useState("")
  const [idPostulante, setIdPostulante] = useState("")
  const [birthDate, setBirthDate] = useState("")
  const [age, setAge] = useState("")
  const [cellphone, setCellphone] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  const [district, setDistrict] = useState("")
  const [department, setDepartment] = useState("")
  const [country, setCountry] = useState("")

  const [degree, setDegree] = useState("")
  const [mdInstructionGrade, setmdInstructionGrade] = useState("")
  const [career, setCareer] = useState("")
  const [mdName, setmdName] = useState("")
  const [mdLocation, setmdLocation] = useState("")
  const [school, setSchool] = useState("")
  const [yearsExperience, setYearsExperience] = useState("2-5")
  const [currentlyWorking, setCurrentlyWorking] = useState("")
  const [currentlyWorkplace, setCurrentlyWorkplace] = useState("")
  const [workPositionName, setworkPositionName] = useState("")
  const [workSector, setworkSector] = useState("")
  const [workPosition, setworkPosition] = useState("")
  const [workLocation, setworkLocation] = useState("")
  const [previouslyApplied, setPreviouslyApplied] = useState("")
  const [yearApplied, setYearApplied] = useState("")
  const [stepApplied, setStepApplied] = useState("")
  const [userEdx, setUserEdx] = useState("")

  const [knowApplicationProcess, setKnowApplicationProcess] = useState("")
  const [scholarshipApplication, setScholarshipApplication] = useState("")
  const [completedDSCourseEdx, setCompletedDSCourseEdx] = useState("")
  const [currentlyCompletedDSCourseEdx, setCurrentlyCompletedDSCourseEdx] = useState("")
  const [completedDSCourse, setCompletedDSCourse] = useState("")
  const [schoolDSCourse, setSchoolDSCourse] = useState("")
  const [nameDSCourse, setNameDSCourse] = useState("")
  const [dateStartDSCourse, setDateStartDSCourse] = useState("")
  const [dateEndDSCourse, setDateEndDSCourse] = useState("")
  const [certificateDSCourse, setCertificateDSCourse] = useState("")
  const [schoolDSCourse2, setSchoolDSCourse2] = useState("")
  const [nameDSCourse2, setNameDSCourse2] = useState("")
  const [dateStartDSCourse2, setDateStartDSCourse2] = useState("")
  const [dateEndDSCourse2, setDateEndDSCourse2] = useState("")
  const [certificateDSCourse2, setCertificateDSCourse2] = useState("")
  const [englishLevel, setEnglishLevel] = useState("Intermedio")
  const [interestApplication, setInterestApplication] = useState("")
  const [urlCV, setUrlCV] = useState("")

  // Modales
  const [isLoading, setIsLoading] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [check, setCheck] = useState(true)
  const [messageModal, setMessageModal] = useState("")

  // Restricciones de campos obligatorios
  const [passToForm2, setPassToForm2] = useState(false)
  useEffect(() => {
    setPassToForm2(
      !!name &&
        !!lastName &&
        !!lastName2 &&
        !!idPostulante &&
        !!birthDate &&
        !! age &&
        !!cellphone &&
        !!email &&
        !!address &&
        !!district &&
        !!department &&
        !!country
    )
  }, [
    name,
    lastName,
    lastName2,
    idPostulante,
    birthDate,
    age,
    cellphone,
    email,
    address,
    district,
    department,
    country,
  ])

  const [passToForm3, setPassToForm3] = useState(false)
  useEffect(() => {
    setPassToForm3(
      !!degree &&
        !!career &&
        !!school &&
        !!yearsExperience &&
        !!currentlyWorking &&
        !!previouslyApplied &&
        !!userEdx
    )
  }, [
    degree,
    career,
    school,
    yearsExperience,
    currentlyWorking,
    previouslyApplied,
    userEdx,
  ])

  const [passToForm4, setPassToForm4] = useState(false)
  useEffect(() => {
    setPassToForm4(

        !!completedDSCourseEdx &&
        !!completedDSCourse &&
        !!englishLevel &&
        !!interestApplication &&
        !!urlCV
    )
  }, [
    knowApplicationProcess,
    scholarshipApplication,
    completedDSCourseEdx,
    completedDSCourse,
    englishLevel,
    interestApplication,
    urlCV,
  ])

  const registerApplicant = async () => {
    let body = {
      name,
      lastName,
      lastName2,
      idPostulante,
      birthDate,
      age,
      cellphone,
      email,
      address,
      district,
      department,
      country,
      degree,
      career,
      school,
      yearsExperience,
      currentlyWorking,
      currentlyWorkplace,
      previouslyApplied,
      yearApplied,
      stepApplied,
      userEdx,
      knowApplicationProcess,
      
      completedDSCourseEdx,
      currentlyCompletedDSCourseEdx,
      completedDSCourse,
      schoolDSCourse,
      nameDSCourse,
      dateStartDSCourse,
      dateEndDSCourse,
      certificateDSCourse,
      schoolDSCourse2,
      nameDSCourse2,
      dateStartDSCourse2,
      dateEndDSCourse2,
      certificateDSCourse2,
      englishLevel,
      interestApplication,
      urlCV,

      workSector,
      workPosition,
      workLocation,
      mdInstructionGrade,
      mdName,
      mdLocation,
      workPositionName,
    }
    
    try {
      const data = await fetch(
        "https://app-landing-aporta.azurewebsites.net/api/RegistroPostulante",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      )
      const response = await data.json()
      if (response.error) {
        setIsLoading(false)
        setCheck(false)
        setMessageModal("Ocurrió un error. Por favor inténtelo de nuevo.")
      } else {
        setIsLoading(false)
        setCheck(true)
        setMessageModal(
          "Gracias, en las próximas horas recibirás un correo de confirmación de registro de tu postulación y los próximos pasos del proceso de admisión"
        )
      }
     
    } catch (error) {
      console.log(error)
    }
  }

  function onChangeForm() {
    if (formActived === 4) {
      navigate(`/`)
    } else {
      setFormActived(formActived + 1)
      navigate(`#`)
    }
  }

  let form = (
    <FormStep1
      name={name}
      setName={setName}
      lastName={lastName}
      setLastName={setLastName}
      lastName2={lastName2}
      setLastName2={setLastName2}
      idPostulante={idPostulante}
      setIdPostulante={setIdPostulante}
      birthDate={birthDate}
      setBirthDate={setBirthDate}
      age={age}
      setAge={setAge}
      cellphone={cellphone}
      setCellphone={setCellphone}
      email={email}
      setEmail={setEmail}
      address={address}
      setAddress={setAddress}
      district={district}
      setDistrict={setDistrict}
      department={department}
      setDepartment={setDepartment}
      country={country}
      setCountry={setCountry}
      passToForm2={passToForm2}
      summit={onChangeForm}
    />
  )
  switch (formActived) {
    case 2:
      form = (
        <FormStep2
          degree={degree}
          setDegree={setDegree}
          career={career}
          setCareer={setCareer}
          school={school}
          setSchool={setSchool}
          yearsExperience={yearsExperience}
          setYearsExperience={setYearsExperience}
          currentlyWorking={currentlyWorking}
          setCurrentlyWorking={setCurrentlyWorking}
          currentlyWorkplace={currentlyWorkplace}
          setCurrentlyWorkplace={setCurrentlyWorkplace}
          previouslyApplied={previouslyApplied}
          setPreviouslyApplied={setPreviouslyApplied}
          yearApplied={yearApplied}
          setYearApplied={setYearApplied}
          stepApplied={stepApplied}
          setStepApplied={setStepApplied}
          userEdx={userEdx}
          setUserEdx={setUserEdx}
          passToForm3={passToForm3}
          summit={onChangeForm}

          mdInstructionGrade = {mdInstructionGrade}
          setmdInstructionGrade={setmdInstructionGrade}
          mdName={mdName}
          setmdName={setmdName}
          mdLocation = {mdLocation}
          setmdLocation={setmdLocation}
          workPositionName={workPositionName}
          setworkPositionName={setworkPositionName}
          workSector={workSector}
          setworkSector={setworkSector}
          workPosition={workPosition}
          setworkPosition={setworkPosition}
          workLocation={workLocation}
          setworkLocation={setworkLocation}
        />
      )
      break
    case 3:
      form = (
        <FormStep3
          // knowApplicationProcess={knowApplicationProcess}
          // setKnowApplicationProcess={setKnowApplicationProcess}
          scholarshipApplication={scholarshipApplication}
          setScholarshipApplication={setScholarshipApplication}
          completedDSCourseEdx={completedDSCourseEdx}
          setCompletedDSCourseEdx={setCompletedDSCourseEdx}
          currentlyCompletedDSCourseEdx= {currentlyCompletedDSCourseEdx}
          setCurrentlyCompletedDSCourseEdx= {setCurrentlyCompletedDSCourseEdx}
          completedDSCourse={completedDSCourse}
          setCompletedDSCourse={setCompletedDSCourse}
          schoolDSCourse={schoolDSCourse}
          setSchoolDSCourse={setSchoolDSCourse}
          nameDSCourse={nameDSCourse}
          setNameDSCourse={setNameDSCourse}
          dateStartDSCourse={dateStartDSCourse}
          setDateStartDSCourse={setDateStartDSCourse}
          dateEndDSCourse={dateEndDSCourse}
          setDateEndDSCourse={setDateEndDSCourse}
          certificateDSCourse={certificateDSCourse}
          setCertificateDSCourse={setCertificateDSCourse}
          schoolDSCourse2={schoolDSCourse2}
          setSchoolDSCourse2={setSchoolDSCourse2}
          nameDSCourse2={nameDSCourse2}
          setNameDSCourse2={setNameDSCourse2}
          dateStartDSCourse2={dateStartDSCourse2}
          setDateStartDSCourse2={setDateStartDSCourse2}
          dateEndDSCourse2={dateEndDSCourse2}
          setDateEndDSCourse2={setDateEndDSCourse2}
          certificateDSCourse2={certificateDSCourse2}
          setCertificateDSCourse2={setCertificateDSCourse2}
          englishLevel={englishLevel}
          setEnglishLevel={setEnglishLevel}
          interestApplication={interestApplication}
          setInterestApplication={setInterestApplication}
          idPostulante={idPostulante}
          name={name}
          lastName={lastName}
          lastName2={lastName2}
          urlCV={urlCV}
          setUrlCV={setUrlCV}
          passToForm4={passToForm4}
          summit={onChangeForm}
        />
      )
      break
    case 4:
      form = (
        <FormStep4
          setOpenModal={setOpenModal}
          onHandleClick={registerApplicant}
        />
      )
      break
    default:
      break
  }

  return (
    <React.Fragment>
      <CustomModal
        isLoading={isLoading}
        title={messageModal}
        modal={openModal}
        handleClick={() => navigate("/")}
        check={check}
      />
      <FormHeader
        passToForm2={passToForm2}
        passToForm3={passToForm3}
        passToForm4={passToForm4}
        active={formActived}
        setFormActived={setFormActived}
      />
      <FormBodyMainDiv>
        <FormBodyDiv>{form}</FormBodyDiv>
      </FormBodyMainDiv>
    </React.Fragment>
  )
}
