import React, { useState ,useEffect} from "react"
import styled from "styled-components"
import { Label, Input } from "reactstrap"
import {
  Slider,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@material-ui/core"
import useContentful from "../../../utils/useContentful"

// import { FormularyEntryId } from "./FormStep1"

const FormTitle = styled.p`
  margin-top: 2rem;
  margin-right: 1rem;
  margin-left: 1rem;
  /*styled text*/
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 136.8%;
  /* identical to box height, or 25px */
  text-align: center;
  letter-spacing: 0.03em;
  color: #000000;
  @media (min-width: 870px) {
    font-size: 24px;
    margin-top: 4rem;
    margin-bottom: 2rem;
    margin-right: 3rem;
    margin-left: 3rem;
  }
  @media (min-width: 1200px) {
    margin-right: 13rem;
    margin-left: 13rem;
  }
`

const FormSubTitle = styled.p`
  text-align: left;
  margin-top: 3rem;
  margin-bottom: 1rem;
  /*styled text*/
  font-style: normal;
  font-weight: 600;

  line-height: 136.8%;
  /* identical to box height, or 33px */
  letter-spacing: 0.03em;
  color: #000000;
  font-size: 12px;
  margin-right: 1rem;
  margin-left: 1rem;

  @media (min-width: 870px) {
    font-size: 24px;
    margin-right: 3rem;
    margin-left: 3rem;
  }
  @media (min-width: 1200px) {
    margin-right: 5rem;
    margin-left: 5rem;
  }
`

const FormParagraph = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  /*styled text*/
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 136.8%;
  /* identical to box height, or 25px */
  text-align: justify;
  letter-spacing: 0.03em;
  color: #000000;
  @media (min-width: 870px) {
    font-size: 18px;
    margin-right: 3rem;
    margin-left: 3rem;
  }
  @media (min-width: 1200px) {
    margin-right: 5rem;
    margin-left: 5rem;
  }
`

const Form = styled.div`
  font-size: 12px;
  margin-right: 1rem;
  margin-left: 1rem;

  /*styled text*/
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 136.8%;
  /* identical to box height, or 25px */
  letter-spacing: 0.03em;
  /* light letter */
  display: grid;
  grid-template-columns: auto;

  color: black;
  @media (min-width: 870px) {
    font-size: 18px;
    margin-right: 3rem;
    margin-left: 3rem;
  }

  @media (min-width: 1200px) {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: #007aff;
  }
  .MuiTypography-root,
  .MuiFormControlLabel-label,
  .MuiTypography-body1 {
    font-size: 12px !important;
    @media (min-width: 870px) {
      font-size: 18px !important;
    }
  }
`

const FormInputDiv = styled.div`
  margin-top: 0rem;

  margin-right: 1.5rem;
  display: flex;
  flex-direction: column;

  @media (min-width: 870px) {
    margin-top: 0.5rem;
  }
`

const FormOneColumn = styled.div`
  font-size: 12px;
  margin-right: 1rem;
  margin-left: 1rem;

  /*styled text*/
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 136.8%;
  /* identical to box height, or 25px */
  letter-spacing: 0.03em;
  /* light letter */
  display: grid;
  grid-template-columns: auto;
  color: black;
  @media (min-width: 870px) {
    font-size: 18px;
    margin-right: 3rem;
    margin-left: 3rem;
  }

  @media (min-width: 1200px) {
    grid-template-columns: 6fr 1fr;
    margin-right: 5rem;
    margin-left: 5rem;
  }
`

const FormOne = styled(FormOneColumn)`
  @media (min-width: 870px) {
    width: ${props => props.width || "auto"};
  }
  @media (min-width: 1200px) {
    grid-template-columns: auto;
  }
`

const FormInput = styled(Input)`
  /* bg light */
  padding-left: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 12px;
  background: #f6f9fb;
  border: 1px solid #c6c6c6;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 30px;
  outline: none;
  &:hover {
    border: 1px solid #68c6a4;
  }
  &:focus {
    border: 1px solid #68c6a4;
  }
  @media (min-width: 870px) {
    font-size: 18px;
    height: 45px;
  }
`

const SummitButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  width: 120px;
  height: 30px;
  font-size: 12px;

  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  background: #68c6a4;
  border-radius: 8px;
  border: 1px solid white;
  font-style: normal;
  font-weight: 600;

  line-height: 136.8%;
  /* identical to box height, or 33px */

  letter-spacing: 0.03em;

  color: #ffffff;

  @media (min-width: 870px) {
    margin-top: 4rem;
    width: 194px;
    height: 45px;
    font-size: 18px;
    margin-bottom: 6rem;
  }
`

const StyledLabel = styled(Label)`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 12px;
  @media (min-width: 870px) {
    font-size: 18px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
`
const StyledSlider = styled(Slider)`
  color: #68c6a4 !important;
  width: 90% !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  font-size: 12px !important;
  .MuiSlider-track {
    height: 15%;
  }
  .MuiSlider-thumb {
    width: 17px;
    height: 17px;
  }
  @media (min-width: 870px) {
    font-size: 18px !important;
    margin-top: 1.5rem;
  }
`
const marks = [
  {
    value: 0,
    label: "No tengo",
  },
  {
    value: 1,
    label: "0-1",
  },
  {
    value: 2,
    label: "2-5",
  },
  {
    value: 3,
    label: "6-10",
  },
  {
    value: 4,
    label: "10-15",
  },
  {
    value: 5,
    label: "+15",
  },
]

const dataStages = [
  { value: "", label: "Seleccione una opción" },
  {
    value: "Completé la expresión de interés, pero no rendí la prueba técnica",
    label: "Completé la expresión de interés, pero no rendí la prueba técnica",
  },
  {
    value: "Rendí y no aprobé la prueba técnica",
    label: "Rendí y no aprobé la prueba técnica",
  },
  {
    value: "No pasé la evaluación de inglés",
    label: "No pasé la evaluación de inglés",
  },
  {
    value: "Llegué hasta la entrevista personal",
    label: "Llegué hasta la entrevista personal",
  },
  {
    value: "Fui admitido, pero no accedí a ninguna opción de financiamiento",
    label: "Fui admitido, pero no accedí a ninguna opción de financiamiento",
  },
]

const dataInstruction = [
  { value: "", label: "Seleccione una opción" },
  { value: "Secundaria Completa", label: "Secundaria Completa" },
  { value: "Estudiante universitario", label: "Estudiante universitario" },
  { value: "Formación técnica", label: "Formación técnica" },
  { value: "Bachiller", label: "Bachiller" },
  { value: "Licenciado", label: "Licenciado" },
  { value: "Maestría", label: "Maestría" },
  { value: "Otros", label: "Otros" },
]

function FormDiv(props) {


  return (
    <FormInputDiv>
      {props.label && <p>{props.label}</p>}
      <FormInput
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      >
        {props.data?.map(option => (
          <option value={option.value}>{option.label}</option>
        ))}
      </FormInput>
    </FormInputDiv>
  )
}

/* function FormDiv(props) {
  return (
    <FormInputDiv>
      <p>{props.label}</p>
      <FormInput
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
      />
    </FormInputDiv>
  )
} */

const FormularyEntryId = "7jS34gPWjA9suoWaDZZfRP";

function FormStep2(props) {
  const { data: formulario, fetched } = useContentful({ id: FormularyEntryId });

  const [sitrabaja , setSitrabaja] = useState(0);
  const [ismagister , setIsmagister] = useState(0);
  const [ismdInstructionGrade , setIsmdInstructionGrade] = useState(0);
  const [isothersection , setIsothersection] = useState(0);
  const [haspostulado , setHaspostulado] = useState(0);


  useEffect(() => {

  if (props.currentlyWorking ==="Sí"){
setSitrabaja(1);
  }
    else{
      setSitrabaja(0);
    }

  if (props.degree ==="Maestría"){
    setIsmagister(1);
      }
      else{
        setIsmagister(0);
      }


  if (props.degree ==="Otros"){
    setIsmdInstructionGrade(1);
      }
      else{
        setIsmdInstructionGrade(0);
      }


  if (props.currentlyWorkplace ==="Otro"){
    setIsothersection(1);
      }
      else{
        setIsothersection(0);
      }


    if (props.previouslyApplied ==="Sí"){
      setHaspostulado(1);
        }
        else{
          setHaspostulado(0);
        }

      
    
  
  });
  console.table("formulario",formulario)

  return (
    <>
    {fetched ?

    <React.Fragment>
    <FormTitle>{formulario.fields.titulo}</FormTitle>
      <FormSubTitle>Formación académica</FormSubTitle>
      <Form style={{ marginTop: "2%", columnGap: "10%" }}>
        <FormDiv
          value={props.degree}
          onChange={props.setDegree}
          label={formulario.fields.gradoDeInstruccin}
          type="select"
          data={dataInstruction}
        />

        {ismagister ? <><br/><br/></>:
        <FormDiv
        value={ismdInstructionGrade?props.mdInstructionGrade:props.career }
        onChange={ismdInstructionGrade? props.setmdInstructionGrade :props.setCareer}
        label={formulario.fields.carreraProfesional}
        type="text"
      />
        }

{ismdInstructionGrade? <>
          <FormDiv
          value={props.career}
          onChange={props.setCareer}
          label={formulario.fields.carreraProfesional}
          type="text"
        />
        </>
        :
        <>
        </>
        }
        

{ismagister? <>
  <FormDiv
          value={props.career}
          onChange={props.setCareer}
          label="Nombre de su maestría******************"
          type="text"
        />

  <FormDiv
          value={props.school}
          onChange={props.setSchool}
          label="Institución educativa******************"
          type="text"
        />
</>:
 <FormDiv
 value={props.school}
 onChange={props.setSchool}
 label={formulario.fields.institucinEducativa}
 type="text"
/>


}


        {ismagister? <>
          <FormDiv
          value={props.mdName}
          onChange={props.setmdName}
          label="Nombre de su bachillerato o licenciatura******************"
          type="text"
        />
          <FormDiv
          value={props.mdLocation}
          onChange={props.setmdLocation}
          label="Institución educativa******************"
          type="text"
        />
        </>
        :
        <>
        </>
        }




        
      </Form>
      <FormSubTitle>
        ¿Cuántos años de experiencia profesional tienes?*
      </FormSubTitle>
      <Form style={{ marginTop: "2%", columnGap: "30%" }}>
      
        <FormDiv
          value={props.yearsExperience}
          onChange={props.setYearsExperience}
          label={formulario.fields.experiencia}
          type="number"
        />
      </Form  >
      {/*<FormOneColumn>
        <StyledSlider
          defaultValue={2}
          aria-labelledby="discrete-slider-restrict"
          step={1}
          marks={marks}
          min={0}
          max={5}
          onChange={(event, newValue) => {
            const _numberLabel = marks.find(mark => mark.value === newValue)
              .label
            props.setYearsExperience(_numberLabel)
          }}
        />
        <FormInput value={props.yearsExperience} readOnly />
      </FormOneColumn>*/}
      <FormSubTitle>¿Te encuentras trabajando actualmente?*</FormSubTitle>
      <Form style={{ width: "250px" ,gridTemplateColumns: "auto"}}>
        <FormDiv
          value={props.currentlyWorking}
          onChange={props.setCurrentlyWorking}
          type="select"
          data={[
            { value: "", label: "Seleccione una opción" },
            { value: "Sí", label: "Sí" },
            { value: "No", label: "No" },
          ]}
        />
        
      </Form>
    { sitrabaja?
    <>
      <FormOneColumn style={{marginTop:"2%"}}>
      <FormDiv
          value={props.currentlyWorkplace}
          onChange={props.setCurrentlyWorkplace}
          type="select"
          label={formulario.fields.sectorTrabajoActual}
          data={[
            { value: "", label: "Seleccione una opción" },
            { value: "Agricultura, ganadería, silvicultura", label: "Agricultura, ganadería, silvicultura" },
            { value: "Alojamiento y restaurantes", label: "Alojamiento y restaurantes" },
            { value: "Banca", label: "Banca" },
            { value: "Comercio y mantenimiento de vehiculos", label: "Comercio y mantenimiento de vehiculos" },
            { value: "Construcción/Inmobiliario", label: "Construcción/Inmobiliario" },
            { value: "Consultoría", label: "Consultoría" },
            { value: "Desarrollo Social", label: "Desarrollo Social" },
            { value: "Educación (enseñando)", label: "Educación (enseñando)" },
            { value: "Electricidad, gas y agua", label: "Electricidad, gas y agua" },
            { value: "Extracción de pertróleo, gas, minerales y servicios conexos", label: "Extracción de pertróleo, gas, minerales y servicios conexos" },
            { value: "Inversión", label: "Inversión" },
            { value: "Manufactura Industrial", label: "Manufactura Industrial" },
            { value: "Medio Ambiente", label: "Medio Ambiente" },
            { value: "Pesca", label: "Pesca" },
            { value: "Retail & wholesale", label: "Retail & wholesale" },
            { value: "Sanidad y salud", label: "Sanidad y salud" },
            { value: "Seguros", label: "Seguros" },
            { value: "Servicios de información y comunicación", label: "Servicios de información y comunicación" },
            { value: "Tecnología", label: "Tecnología" },
            { value: "Transporte", label: "Transporte" },
            { value: "Turismo", label: "Turismo" },
            { value: "Otro", label: "Otro" },
           
            
          ]}
        />
      </FormOneColumn>

      {isothersection? <>
        <Form style={{ marginTop: "2%", columnGap: "20%" }}>
          <FormDiv
          value={props.workPositionName}
          onChange={props.setworkPositionName}
          label="Indique el nombre de la sección"
          type="text"
        />
        </Form>
        </>
        :
        <>
        </>
        }
      
      <Form style={{ marginTop: "2%", columnGap: "20%" }}>
        
      <FormDiv
          value={props.workSector}
          onChange={props.setworkSector}
          type="select"
          label = {formulario.fields.posicinActual}
          data={[
            { value: "", label: "Seleccione una opción" },
            { value: "Practicante", label: "Practicante" },
            { value: "Asistente", label: "Asistente" },
            { value: "Analista", label: "Analista" },
            { value: "Especialista", label: "Especialista" },
            { value: "Coordinador", label: "Coordinador" },
            { value: "Jefatura", label: "Jefatura" },
            { value: "Investigador", label: "Investigador" },
          ]}
        />
        <FormDiv
          value={props.workPosition}
          onChange={props.setworkPosition}
          label={formulario.fields.nombreDePosicin}
          type="text"
        />


      </Form>

      <FormOneColumn style={{marginTop:"2%"}}>
      <FormDiv
          value={props.workLocation}
          onChange={props.setworkLocation}
          label={formulario.fields.organizacinDondeLabora}
          type="text"
        />
      </FormOneColumn>

      
      </>
      :
      <> </>
      }
      <FormSubTitle>
        ¿Has postulado anteriormente al programa?*
        <br />
        (Esta información no afecta a tu postulación)
      </FormSubTitle>
      <Form>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            name="previouslyApplied"
            value={props.previouslyApplied}
            onChange={e => props.setPreviouslyApplied(e.target.value)}
          >
            <FormControlLabel value="Sí" control={<Radio />} label="Sí" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        {haspostulado? 
        <FormOne width="250px">
          <FormDiv
            value={props.yearApplied}
            onChange={props.setYearApplied}
            label={formulario.fields.aoPostulacinAnterior}
            type="select"
            data={[
              { value: "", label: "Seleccione una opción" },
              { value: "Generación 1 (Mayo 2019)", label: "Generación 1 (Mayo 2019)" },
              { value: "Generación 2 (Mayo 2020)", label: "Generación 2 (Mayo 2020)" },
              { value: "Generación 3 (Setiembre 2020)", label: "Generación 3 (Setiembre 2020)" },
              { value: "Generación 4 (Mayo 2021)", label: "Generación 4 (Mayo 2021)" },
              { value: "Generación 5 (Setiembre 2021)", label: "Generación 5 (Setiembre 2021)" },
              { value: "Generación 6 (Mayo 2022)", label: "Generación 6 (Mayo 2022)" },
              { value: "Generación 7 (Septiembre 2022)", label: "Generación 7 (Septiembre 2022)" },
            ]}
          />
        </FormOne> : <></>}


        
      </Form>

      {
        haspostulado? 
        <>
        <FormSubTitle>
        {formulario.fields.postulacinAnterior}
      </FormSubTitle>
      <FormOne width="55%">
        <FormDiv
          value={props.stepApplied}
          onChange={props.setStepApplied}
          type="select"
          data={dataStages}
        />
      </FormOne>
        </>: 
        <></>
      }
      


      <FormSubTitle>Indícanos tu usuario de edX*</FormSubTitle>
      <FormParagraph>
      {formulario.fields.usuarioEdx}
      </FormParagraph>
      <FormOneColumn>
        <FormDiv
          value={props.userEdx}
          onChange={props.setUserEdx}
          type="text"
        />
      </FormOneColumn>
      <br />
      <FormParagraph style={{ color: "red", fontSize: "12px" }}>
        *Estos campos son obligatorios y necesarios para continuar con el formulario.
      </FormParagraph>
      <SummitButton
        onClick={() => {
          if (props.passToForm3) {
            props.summit()
          }
        }}
      >
        SIGUIENTE
      </SummitButton>
      {/* <SummitButton
        onClick={() => {
          props.summit()
        }}
      >
        SIGUIENTE
      </SummitButton> */}
  </React.Fragment>
    :
    <div
    style={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      marginTop: "0px",
      padding: "15%",
    }}
  >
    {" "}
    <svg
      version="1.1"
      id="L9"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBlackground="new 0 0 0 0"
      xmlSpace="preserve"
    >
      <path
        fill="#008991"
        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="1s"
          from="0 50 50"
          to="360 50 50"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </div>
  }
    </>
  )
}

export default FormStep2
