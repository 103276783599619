import React from "react"

//importación de componentes
import MainLayout from "../components/Layouts/MainLayout"
import FormContainer from "../components/FormContainer"

export default function FormOculto() {
  return (
    <MainLayout active={6}>
      <FormContainer />
    </MainLayout>
  )
}
