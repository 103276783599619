import React, { useState } from "react"
import styled from "styled-components"
import { FormGroup, Label, Input } from "reactstrap"
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@material-ui/core"
import useContentful from "../../../utils/useContentful"

const FormTitle = styled.p`
  margin-top: 2rem;
  margin-right: 1rem;
  margin-left: 1rem;
  /*styled text*/
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 136.8%;
  /* identical to box height, or 25px */
  text-align: center;
  letter-spacing: 0.03em;
  color: #000000;
  @media (min-width: 870px) {
    font-size: 24px;
    margin-bottom: 3rem;
    margin-top: 4rem;
    margin-right: 3rem;
    margin-left: 3rem;
  }
  @media (min-width: 1200px) {
    margin-right: 13rem;
    margin-left: 13rem;
  }
`

const FormSubTitle = styled.p`
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 1rem;

  /*styled text*/
  font-style: normal;
  font-weight: 600;

  line-height: 136.8%;
  /* identical to box height, or 33px */
  letter-spacing: 0.03em;
  color: #000000;
  font-size: 12px;
  margin-right: 1rem;
  margin-left: 1rem;

  @media (min-width: 870px) {
    font-size: 24px;
    margin-right: 3rem;
    margin-left: 3rem;
  }
  @media (min-width: 1200px) {
    margin-right: 5rem;
    margin-left: 5rem;
  }
`

const FormParagraph = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  /*styled text*/
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 136.8%;
  /* identical to box height, or 25px */
  text-align: justify;
  letter-spacing: 0.03em;
  color: #000000;
  @media (min-width: 870px) {
    font-size: 18px;
    margin-right: 3rem;
    margin-left: 3rem;
  }
  @media (min-width: 1200px) {
    margin-right: 5rem;
    margin-left: 5rem;
  }
`

const Form = styled.div`
  font-size: 12px;
  margin-right: 1rem;
  margin-left: 1rem;

  /*styled text*/
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 136.8%;
  /* identical to box height, or 25px */
  letter-spacing: 0.03em;
  /* light letter */
  display: grid;
  grid-template-columns: auto;

  color: black;
  @media (min-width: 870px) {
    font-size: 18px;
    margin-right: 3rem;
    margin-left: 3rem;
  }

  @media (min-width: 1200px) {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  @media (min-width: 600px) {
    grid-template-columns: auto auto;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: #007aff;
  }
  .MuiTypography-root,
  .MuiFormControlLabel-label,
  .MuiTypography-body1 {
    font-size: 12px !important;
    @media (min-width: 870px) {
      font-size: 18px !important;
    }
  }
`

const SummitButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  width: 120px;
  height: 30px;
  font-size: 12px;

  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  background: #68c6a4;
  border-radius: 8px;
  border: 1px solid white;
  font-style: normal;
  font-weight: 600;

  line-height: 136.8%;
  letter-spacing: 0.03em;
  color: #ffffff;

  @media (min-width: 870px) {
    margin-top: 4rem;
    width: 194px;
    height: 45px;
    font-size: 18px;
    margin-bottom: 6rem;
  }
`

const StyledImg = styled.img`
  width: 60%;
  margin-left: auto;
  margin-right: auto;
`
const FormularyEntryId = "7jS34gPWjA9suoWaDZZfRP"
function FormStep4(props) {
  const { data: formulario, fetched } = useContentful({ id: FormularyEntryId });

  const [codeOfHonor, setCodeOfHonor] = useState("")
  const [personalInformation, setPersonalInformation] = useState("")

  return (
    <>
    {fetched ?

    <React.Fragment>
      <FormTitle>{formulario.fields.titulo}</FormTitle>
      <FormSubTitle>CÓDIGO DE HONOR</FormSubTitle>
      <FormParagraph>
      {formulario.fields.codigoHonor}
      </FormParagraph>
      <StyledImg src={formulario.fields.codigoHonorImagen.fields.file.url}/>
      <Form>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            name="previouslyApplied"
            value={codeOfHonor}
            onChange={e => setCodeOfHonor(e.target.value)}
          >
            <FormControlLabel
              value="He leído y ACEPTO el Código
              de Honor del Programa"
              control={<Radio />}
              label="He leído y ACEPTO el Código
              de Honor del Programa"
            />
          </RadioGroup>
        </FormControl>
      </Form>
      <FormSubTitle>PROTECCIÓN Y TRATAMIENTO DE DATOS PERSONALES</FormSubTitle>
      <FormParagraph>
      {formulario.fields.tratamientoDatos}
      </FormParagraph>
      <StyledImg src={formulario.fields.tratamientoDatosImagen.fields.file.url}/>
      
  
      <Form>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            name="previouslyApplied"
            value={personalInformation}
            onChange={e => setPersonalInformation(e.target.value)}
          >
            <FormControlLabel
              value="He leído y ACEPTO la cláusula
              de Protección y Tratamiento de Datos Personales"
              control={<Radio />}
              label="He leído y ACEPTO la cláusula
              de Protección y Tratamiento de Datos Personales"
            />
          </RadioGroup>
        </FormControl>
      </Form>
      <SummitButton
        disabled={!codeOfHonor || !personalInformation}
        onClick={props.onHandleClick}
        onClick={() => {
          props.setOpenModal(true)
          props.onHandleClick()
        }}
      >
        ENVIAR
      </SummitButton>
    </React.Fragment>
        :
        <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          marginTop: "0px",
          padding: "15%",
        }}
      >
        {" "}
        <svg
          version="1.1"
          id="L9"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          enableBlackground="new 0 0 0 0"
          xmlSpace="preserve"
        >
          <path
            fill="#008991"
            d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="1s"
              from="0 50 50"
              to="360 50 50"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </div>
      }
    </>
  )
}

export default FormStep4
