import React, { useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import useContentful from "../../../utils/useContentful"
import BoldContentful from "../../../utils/BoldContentful"

const FormTitle = styled.p`
  margin-top: 2rem;
  margin-right: 1rem;
  margin-left: 1rem;
  /*styled text*/
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 136.8%;
  /* identical to box height, or 25px */
  text-align: center;
  letter-spacing: 0.03em;
  color: #000000;
  @media (min-width: 870px) {
    font-size: 24px;
    margin-top: 4rem;
    margin-right: 3rem;
    margin-left: 3rem;
  }
  @media (min-width: 1200px) {
    margin-right: 13rem;
    margin-left: 13rem;
  }
`

const FormSubTitle = styled.p`
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 1rem;

  /*styled text*/
  font-style: normal;
  font-weight: 600;

  line-height: 136.8%;
  /* identical to box height, or 33px */
  letter-spacing: 0.03em;
  color: #000000;
  font-size: 12px;
  margin-right: 1rem;
  margin-left: 1rem;

  @media (min-width: 870px) {
    font-size: 24px;
    margin-right: 3rem;
    margin-left: 3rem;
  }
`

const FormParagraph = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 136.8%;
  text-align: justify;
  letter-spacing: 0.03em;
  color: #000000;
  @media (min-width: 870px) {
    font-size: 18px;
    margin-right: 3rem;
    margin-left: 3rem;
    padding-right: 3rem;
  }
`

const Form = styled.div`
  font-size: 12px;
  margin-right: 1rem;
  margin-left: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 136.8%;
  letter-spacing: 0.03em;
  display: grid;
  grid-template-columns: auto;
  color: #767b8a;
  @media (min-width: 870px) {
    font-size: 18px;
    margin-right: 3rem;
    margin-left: 3rem;
  }
  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
`

const FormInputDiv = styled.div`
  margin-top: 0rem;
  margin-right: 1.5rem;
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 0;
  }
  small {
    font-size: 10px;
  }
  div {
    width: 80%;
  }
  @media (min-width: 600px) {
    div {
      height: 70px;
    }
  }
  @media (min-width: 870px) {
    margin-top: 1rem;
    small {
      font-size: 11px;
    }
    div {
      height: 50px;
      width: 80%;
    }
  }
  @media (min-width: 871px) and (max-width: 1300px) {
    div {
      height: 75px;
      width: 80%;
    }
  }
`

const FormInput = styled.input`
  padding-left: 0.5rem;
  margin-bottom: 1rem;
  font-size: 12px;
  background: #f6f9fb;
  border: 1px solid #c6c6c6;
  box-sizing: border-box;
  border-radius: 8px;
  width: 80%;
  height: 30px;
  outline: none;
  &:hover {
    border: 1px solid #68c6a4;
  }
  &:focus {
    border: 1px solid #68c6a4;
  }
  @media (min-width: 870px) {
    font-size: 18px;
    height: 45px;
  }
`

const SummitButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  width: 120px;
  height: 30px;
  font-size: 12px;

  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  background: #68c6a4;
  border-radius: 8px;
  border: 1px solid white;
  font-style: normal;
  font-weight: 600;

  line-height: 136.8%;
  /* identical to box height, or 33px */

  letter-spacing: 0.03em;

  color: #ffffff;

  @media (min-width: 870px) {
    margin-top: 4rem;
    width: 194px;
    height: 45px;
    font-size: 18px;
    margin-bottom: 6rem;
  }
`

function FormDiv(props) {
  return (
    <FormInputDiv>
      <div className="divText">
        <p>{props.label}</p>
        <small>{props.small}</small>
      </div>
      <FormInput
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        disabled={props.disabled}
        onChange={e => props.onChange(e.target.value)}
      />
    </FormInputDiv>
  )
}


//     let birthDateHold = props.birthDate.value
//     let today = new Date()
//     let age = today.getFullYear() - birthDateHold.getMonth()
//     if (
//       monthDiference < 0 ||
//       (monthDiference === 0 && today.getDate() < birthDateHold.getDate()){
//         age--;
//       }
//     )
//   })

// }

const FormularyEntryId = "7jS34gPWjA9suoWaDZZfRP"

function FormStep1(props) {
  // function getAge(date) {
  //   let today = new Date()
  
  // }
  const { data: formulario, fetched } = useContentful({ id: FormularyEntryId });
  useEffect(()=> {
    var years = moment().diff(props.birthDate, 'years',false);
       
      props.setAge(years);
  },[props.birthDate])

 
  return (
  <>
    {fetched ?

    <React.Fragment>
    <FormTitle>{formulario.fields.titulo}</FormTitle>
    <FormParagraph>{BoldContentful(formulario.fields.description)}</FormParagraph>
    <FormSubTitle>Datos personales</FormSubTitle>
    <Form>
      <FormDiv
        value={props.name}
        onChange={props.setName}
        label={formulario.fields.nombres}
        type="text"
      />
      <FormDiv
        value={props.lastName}
        onChange={props.setLastName}
        label={formulario.fields.apellidoPaterno}
        type="text"
      />
      <FormDiv
        value={props.lastName2}
        onChange={props.setLastName2}
        label={formulario.fields.apellidoMaterno}
        type="text"
      />
      <FormDiv
        value={props.idPostulante}
        onChange={props.setIdPostulante}
        label={formulario.fields.dni}
        type="number"
      />
      <FormDiv
        value={props.birthDate}
        onChange={props.setBirthDate}
        type="date"
        label={formulario.fields.fechaDeNacimiento}
      />

      <FormDiv
        value={props.age}
        disabled = {true}
        type="number"
        label={formulario.fields.edad}

        // actualAge={() => { useEffect* 
        //   let actualDate = new Date();
            
        // }
      />

      <FormDiv
        value={props.cellphone}
        onChange={props.setCellphone}
        label={formulario.fields.nmeroDeTelfono}
        type="number"
      />
      <FormDiv
        value={props.email}
        onChange={props.setEmail}
        label={formulario.fields.eMail}
        type="email"
        small="Recuerda que este correo debe estar asociado a tu cuenta de edX."
      />
      <FormDiv
        value={props.address}
        onChange={props.setAddress}
        label={formulario.fields.direccin}
        type="text"
      />
      <FormDiv
        value={props.district}
        onChange={props.setDistrict}
        label={formulario.fields.distrito}
        type="text"
      />
      <FormDiv
        value={props.department}
        onChange={props.setDepartment}
        label={formulario.fields.departamento}
        type="text"
      />
      <FormDiv
        value={props.country}
        onChange={props.setCountry}
        label={formulario.fields.pas}
        type="text"
      />
    </Form>
    <FormParagraph style={{ color: "red", fontSize: "12px" }}>
      *Estos campos son obligatorios y necesarios para continuar con el formulario.
    </FormParagraph>
    <SummitButton
      onClick={() => {
        if (props.passToForm2) {
          props.summit()
        }
      }}
    >
      SIGUIENTE
    </SummitButton>
    {/* <SummitButton
      onClick={() => {
        props.summit()
      }}
    >
      SIGUIENTE
    </SummitButton> */}
  </React.Fragment>
    :
    <div
    style={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      marginTop: "0px",
      padding: "15%",
    }}
  >
    {" "}
    <svg
      version="1.1"
      id="L9"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBlackground="new 0 0 0 0"
      xmlSpace="preserve"
    >
      <path
        fill="#008991"
        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="1s"
          from="0 50 50"
          to="360 50 50"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </div>
  }
</>
    
  )
}

export default FormStep1
