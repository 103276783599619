import React from "react"
import styled from "styled-components"

const FormHeaderMainDiv = styled.div`
  width: 100%;
  padding-top: 5rem;
  background-color: #d2eee4;
  align-items: center;
`

const FormHeaderDiv = styled.div`
  width: 80%;
  padding-top: 1rem;
  margin-right: auto;
  margin-left: auto;
  background-color: white;
  display: flex;
  justify-content: space-around;
  @media (min-width: 530px) {
    padding-top: 5rem;
  }
`
const FormHeaderStepDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StepBall = styled.div`
  border-radius: 50%;

  margin-bottom: 0.5rem;
  margin-right: auto;
  margin-left: auto;
  background-color: #c4c4c4;
  height: 40px;
  width: 40px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*styled text*/
  font-style: normal;
  font-weight: 500;
  cursor: pointer;

  line-height: 136.8%;
  letter-spacing: 0.03em;
  color: #ffffff;
  @media (min-width: 870px) {
    height: 59px;
    width: 59px;
    font-size: 24px;
  }
  ${({ active }) => active && `background-color: #ec5959;`}
`

const StepTitle = styled.p`
  margin-right: 1rem;
  margin-left: 1rem;
  /*styled text*/
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 136.8%;
  /* identical to box height, or 25px */
  text-align: center;
  letter-spacing: 0.03em;
  color: #000000;
  display: none;
  @media (min-width: 870px) {
    font-size: 18px;
    margin-right: 2rem;
    margin-left: 2rem;
  }

  @media (min-width: 530px) {
    display: block;
  }
`

function FormHeaderStep(props) {
  return (
    <FormHeaderStepDiv>
      <StepBall
        active={props.active}
        /* onClick={() => props.setFormActived(props.number)} */
        onClick={props.onClick}
      >
        {props.number}
      </StepBall>
      <StepTitle>{props.title}</StepTitle>
    </FormHeaderStepDiv>
  )
}

function FormHeader(props) {
  return (
    <FormHeaderMainDiv>
      <FormHeaderDiv>
        <FormHeaderStep
          number={1}
          title="Datos personales"
          active={props.active === 1 ? true : false}
          setFormActived={props.setFormActived}
          onClick={() => {
            props.setFormActived(1)
          }}
        />
        <FormHeaderStep
          number={2}
          title="Formación académica y trayectoria profesional"
          active={props.active === 2 ? true : false}
          setFormActived={props.setFormActived}
          onClick={() => {
            if (props.passToForm2) {
              props.setFormActived(2)
            }
          }}
        />
        <FormHeaderStep
          number={3}
          title="Sobre el Programa y CV"
          active={props.active === 3 ? true : false}
          setFormActived={props.setFormActived}
          onClick={() => {
            if (props.passToForm3) {
              props.setFormActived(3)
            }
          }}
        />
        <FormHeaderStep
          number={4}
          title="Tratamiento de datos personales"
          active={props.active === 4 ? true : false}
          setFormActived={props.setFormActived}
          onClick={() => {
            if (props.passToForm4) {
              props.setFormActived(4)
            }
          }}
        />
      </FormHeaderDiv>
    </FormHeaderMainDiv>
  )
}

export default FormHeader
