import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Label, Input } from "reactstrap"
import useContentful from "../../../utils/useContentful"
import BoldContentful from "../../../utils/BoldContentful"


import {
  Slider,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@material-ui/core"

const FormTitle = styled.p`
  margin-top: 2rem;
  margin-right: 1rem;
  margin-left: 1rem;
  /*styled text*/
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 136.8%;
  /* identical to box height, or 25px */
  text-align: center;
  letter-spacing: 0.03em;
  color: #000000;
  @media (min-width: 870px) {
    font-size: 24px;
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-right: 3rem;
    margin-left: 3rem;
  }
  @media (min-width: 1200px) {
    margin-right: 13rem;
    margin-left: 13rem;
  }
`

const FormSubTitle = styled.p`
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 1rem;

  /*styled text*/
  font-style: normal;
  font-weight: 600;

  line-height: 136.8%;
  /* identical to box height, or 33px */
  letter-spacing: 0.03em;
  color: #000000;
  font-size: 14px;
  margin-right: 1rem;
  margin-left: 1rem;

  @media (min-width: 870px) {
    font-size: 24px;
    margin-right: 3rem;
    margin-left: 3rem;
  }
  @media (min-width: 1200px) {
    margin-right: 2rem;
    margin-left: 5rem;
  }
`

const FormParagraph = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  /*styled text*/
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 136.8%;
  /* identical to box height, or 25px */
  text-align: justify;
  letter-spacing: 0.03em;
  color: #000000;
  @media (min-width: 870px) {
    font-size: 18px;
    margin-right: 3rem;
    margin-left: 3rem;
  }
  @media (min-width: 1200px) {
    margin-right: 5rem;
    margin-left: 5rem;
  }
`

const Form = styled.div`
  font-size: 12px;
  margin-right: 1rem;
  margin-left: 1rem;

  /*styled text*/
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 136.8%;
  /* identical to box height, or 25px */
  letter-spacing: 0.03em;
  /* light letter */
  display: grid;
  grid-template-columns: auto;
  color: black;
  p {
    color: #767b8a;
    margin-bottom: 0;
  }
  @media (min-width: 870px) {
    font-size: 18px;
    margin-right: 3rem;
    margin-left: 3rem;
  }

  @media (min-width: 1200px) {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  @media (min-width: 600px) {
    grid-template-columns: auto auto;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: #007aff;
  }
  .MuiTypography-root,
  .MuiFormControlLabel-label,
  .MuiTypography-body1 {
    font-size: 12px !important;
    @media (min-width: 870px) {
      font-size: 18px !important;
    }
  }
`

const FormOneColumn = styled.div`
  font-size: 12px;
  margin-right: 1rem;
  margin-left: 1rem;

  /*styled text*/
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 136.8%;
  /* identical to box height, or 25px */
  letter-spacing: 0.03em;
  /* light letter */
  display: grid;
  grid-template-columns: auto;

  color: black;
  @media (min-width: 870px) {
    font-size: 18px;
    margin-right: 3rem;
    margin-left: 3rem;
  }

  @media (min-width: 1200px) {
    grid-template-columns: 6fr 1fr;
    margin-right: 5rem;
    margin-left: 5rem;
  }
`

const FormInputDiv = styled.div`
  margin-top: 0rem;
  margin-right: 1.5rem;
  display: flex;
  flex-direction: column;
  @media (min-width: 870px) {
    margin-top: 0.5rem;
  }
`
const FormInput = styled.input`
  /* bg light */
  padding-left: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 12px;
  background: #f6f9fb;
  border: 1px solid #c6c6c6;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 30px;
  outline: none;
  &:hover {
    border: 1px solid #68c6a4;
  }
  &:focus {
    border: 1px solid #68c6a4;
  }
  @media (min-width: 870px) {
    font-size: 18px;
    height: 45px;
  }
`

const SummitButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  outline: none;
  width: 120px;
  height: 30px;
  font-size: 12px;

  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  background: #68c6a4;
  border-radius: 8px;
  border: 1px solid white;
  font-style: normal;
  font-weight: 600;

  line-height: 136.8%;
  /* identical to box height, or 33px */

  letter-spacing: 0.03em;

  color: #ffffff;

  @media (min-width: 870px) {
    margin-top: 4rem;
    width: 194px;
    height: 45px;
    font-size: 18px;
    margin-bottom: 6rem;
  }
`

const ChargeButton = styled(Input)`
  width: 100% !important;
  margin-right: auto !important;
  margin-left: auto !important;
  text-align: center;
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  margin-top: 1rem;
  outline: none;
  &:hover {
    border: 1px solid #68c6a4;
  }
  &:focus {
    border: 1px solid #68c6a4;
  }

  @media (min-width: 1000px) {
    width: 60% !important;
    margin-top: 1.5rem;
  }
  margin-bottom: 1rem;
`
const StyledLabel = styled(Label)`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 12px;
  @media (min-width: 870px) {
    font-size: 18px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
`
const StyledSlider = styled(Slider)`
  color: #68c6a4 !important;
  width: 90% !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  font-size: 12px !important;
  .MuiSlider-track {
    height: 15%;
  }
  .MuiSlider-thumb {
    width: 17px;
    height: 17px;
  }
  @media (min-width: 870px) {
    font-size: 18px !important;
    margin-bottom: 4rem !important;
    margin-top: 1.5rem;
  }
`
const marks = [
  {
    value: 0,
    label: "Básico",
  },
  {
    value: 1,
    label: "Intermedio",
  },
  {
    value: 2,
    label: "Alto",
  },
  {
    value: 3,
    label: "Avanzado",
  },
]

const FormOne = styled(FormOneColumn)`
  @media (min-width: 870px) {
    width: ${props => props.width || "auto"};
  }
  @media (min-width: 1200px) {
    grid-template-columns: auto;
  }
`
const FormTextArea = styled.textarea`
  /* bg light */
  /* padding-left: 0.5rem; */
  padding: 0.8rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 12px;
  background: #f6f9fb;
  border: 1px solid #c6c6c6;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 100px;
  resize: none;
  outline: none;
  &:hover {
    border: 1px solid #68c6a4;
  }
  &:focus {
    border: 1px solid #68c6a4;
  }
  @media (min-width: 870px) {
    font-size: 18px;
    height: 200px;
  }
`

function FormDiv(props) {
  return (
    <FormInputDiv>
      <p>{props.label}</p>
      <FormInput
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
    </FormInputDiv>
  )
}

const dataStages = [
  "Si, envío de expresión de interés, prueba técnica online, evaluación de inglés, evaluación personal, comunicación de preadmisión,posible adjudicación de beca, admisión",
  "Si, envío de expresión de interés, posible adjudicación de beca, prueba técnica online, evaluación de inglés, evaluación personal, admisión",
  "Si, prueba técnica online, evaluación de inglés, evaluación personal, posible adjudicación de beca, admisión",
  "Si, envío de expresión de interés, prueba técnica online, evaluación de inglés, evaluación personal, admisión, posible adjudicación de beca",
]

const dataScholarships = [
  "Sí",
  "No, tengo opción de que la empresa para la que laboro pague mi formación",
  "No, prefiero financiarme el costo del programa de manera individual",
]

const FormularyEntryId = "7jS34gPWjA9suoWaDZZfRP"

function FormStep3(props) {
  const { data: formulario, fetched } = useContentful({ id: FormularyEntryId });

  const [numOfWords, setNumOfWords] = useState(0)
  const [llevocurso, setLlevocurso] = useState(0)

  useEffect(() => {
    if (props.interestApplication != "") {
      setNumOfWords(props.interestApplication.trim().split(" ").length || 0)
   
    } else {
      setNumOfWords(0)
    }
  }, [props.interestApplication])

  useEffect(() => {

    if (props.completedDSCourseEdx ==="Sí"){
      setLlevocurso(1);
        }
          else{
            setLlevocurso(0);
          }
  })

  // Carga de cv
  const [message, setMessage] = useState("")
  const [success, setSuccess] = useState(false)

  const uploadCVPostulante = async e => {
    try {
      let formData = new FormData()
      formData.append("cv", e.target.files[0])

      const data = await fetch(
        `https://app-landing-aporta.azurewebsites.net/api/UploadCV?idPostulante=${props.idPostulante}&titulo=${props.name}${props.lastName}
        `,
        {
          method: "POST",
          body: formData,
        }
      )
      const response = await data.json()
    
      if (!response.error) {
        props.setUrlCV(response.data.url_cv)
        setMessage(response.data.respuesta)
        setSuccess(true)
      } else {
        setMessage("Ocurrió un error. Por favor, vuelva a intentarlo")
        setSuccess(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
        {fetched ?

<React.Fragment>
      <FormTitle>{formulario.fields.titulo}</FormTitle>
      {/* Pregunta en desuso */}
      {/* <FormSubTitle>
        ¿Estás familiarizado/a con las etapas que componen el proceso de
        postulación?*
      </FormSubTitle>
      <br />
      <Form>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="knowApplicationProcess"
            name="knowApplicationProcess"
            value={props.knowApplicationProcess}
            onChange={e => props.setKnowApplicationProcess(e.target.value)}
          >
            {dataStages.map(elem => (
              <>
                <FormControlLabel
                  value={elem}
                  control={<Radio />}
                  label={elem}
                />
                <br />
                <br />
              </>
            ))}
          </RadioGroup>
        </FormControl>
      </Form> */}
{/*
      <FormSubTitle>
        ¿Deseas ser incluido/a en el proceso de asignación de becas para
        participar del programa?*
      </FormSubTitle>
      <br />
      <Form>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="scholarshipApplication"
            name="scholarshipApplication"
            value={props.scholarshipApplication}
            onChange={e => props.setScholarshipApplication(e.target.value)}
          >
            {dataScholarships.map(elem => (
              <>
                <FormControlLabel
                  value={elem}
                  control={<Radio />}
                  label={elem}
                />
                <br />
                <br />
              </>
            ))}
          </RadioGroup>
        </FormControl>
      </Form>
*/}
      <FormSubTitle>
      ¿Has completado algún curso de Data Science en la plataforma edX del MIT?*
      </FormSubTitle>
      <br />
      <Form>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="completedDSCourseEdx"
            name="completedDSCourseEdx"
            value={props.completedDSCourseEdx}
            onChange={e => props.setCompletedDSCourseEdx(e.target.value)}>
              <FormControlLabel value="Sí" control={<Radio />} label="Sí" />
            <br />
              <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

{llevocurso?
<>
<FormOneColumn>
          <FormDiv
            value={props.currentlyCompletedDSCourseEdx}
            onChange={props.setCurrentlyCompletedDSCourseEdx}
            label={formulario.fields.cursoPrevio}
            type="text"
          />
      </FormOneColumn>
</>
:<></>
}

      
      </Form>

      <FormSubTitle>
        ¿Has completado algún otro curso de Data Science o relacionado?*
      </FormSubTitle>
      <br />
      <Form>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="completedDSCourse"
            name="completedDSCourse"
            value={props.completedDSCourse}
            onChange={e => props.setCompletedDSCourse(e.target.value)}
          >
            <FormControlLabel value="Sí" control={<Radio />} label="Sí" />
            <br />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Form>
      <br />
      <FormSubTitle>
        De haber respondido sí a la pregunta anterior, por favor especifíca:
        Organización, nombre de curso y fecha de finalización.
        <br />
        Completar la información de los últimos 2 cursos realizados.
      </FormSubTitle>
      <Form style={{ columnGap: "30%" }}>
        <FormDiv
          value={props.schoolDSCourse}
          onChange={props.setSchoolDSCourse}
          label={formulario.fields.institucin}
          type="text"
        />
        <FormDiv
          value={props.nameDSCourse}
          onChange={props.setNameDSCourse}
          label={formulario.fields.nombreDeCurso}
          type="text"
        />
        <FormDiv
          value={props.dateStartDSCourse}
          onChange={props.setDateStartDSCourse}
          label={formulario.fields.inicioCurso}
          type="date"
        />
        <FormDiv
          value={props.dateEndDSCourse}
          onChange={props.setDateEndDSCourse}
          label={formulario.fields.finCurso}
          type="date"
        />
        <FormDiv
          value={props.certificateDSCourse}
          onChange={props.setCertificateDSCourse}
          label={formulario.fields.certificado}
          type="text"
        />
      </Form>
      <br />
      <br />
      <Form style={{ columnGap: "30%" }}>
        <FormDiv
          value={props.schoolDSCourse2}
          onChange={props.setSchoolDSCourse2}
          label={formulario.fields.institucin}
          type="text"
        />
        <FormDiv
          value={props.nameDSCourse2}
          onChange={props.setNameDSCourse2}
          label={formulario.fields.nombreDeCurso}
          type="text"
        />
        <FormDiv
          value={props.dateStartDSCourse2}
          onChange={props.setDateStartDSCourse2}
          label={formulario.fields.inicioCurso}
          type="date"
        />
        <FormDiv
          value={props.dateEndDSCourse2}
          onChange={props.setDateEndDSCourse2}
          label={formulario.fields.finCurso}
          type="date"
        />
        <FormDiv
          value={props.certificateDSCourse2}
          onChange={props.setCertificateDSCourse2}
          label={formulario.fields.certificado}
          type="text"
        />
      </Form>
      <FormSubTitle>
        ¿En una escala del 1 al 4, cuál consideras que es tu nivel de inglés?*
        <br />
        <small>
        {formulario.fields.nivelIngles}
        </small>
      </FormSubTitle>
      <FormOneColumn>
        <StyledSlider
          defaultValue={2}
          aria-labelledby="discrete-slider-restrict"
          step={1}
          marks={marks}
          min={0}
          max={3}
          onChange={(event, newValue) => {
            const _label = marks.find(mark => mark.value === newValue).label
            props.setEnglishLevel(_label)
          }}
        />
        <FormInput value={props.englishLevel} readOnly />
      </FormOneColumn>
      <FormSubTitle>
      Describe en 300 palabras por qué estás interesado/a en postular a nuestro programa*:
      </FormSubTitle>
      <FormParagraph>
      {formulario.fields.interes}
      </FormParagraph>
      <FormOne>
        <FormTextArea
          value={props.interestApplication}
          onChange={e => props.setInterestApplication(e.target.value)}
        />
      </FormOne>
      <FormParagraph style={{ textAlign: "end" }}>
        Te quedan {300 - numOfWords} palabras
      </FormParagraph>
      <FormSubTitle>Carga tu CV*</FormSubTitle>
      <FormParagraph>
{BoldContentful(formulario.fields.cargarCvrich)}      </FormParagraph>
      <FormParagraph
        style={{
          color: `${success ? "green" : "red"}`,
          fontSize: "14px",
        }}
      >
        {message}
      </FormParagraph>
      <FormOneColumn>
        <ChargeButton
          onChange={uploadCVPostulante}
          type="file"
          name="file"
          id="exampleFile"
        />
      </FormOneColumn>
      <br />
      <FormParagraph style={{ color: "red", fontSize: "12px" }}>
        *Estos campos son obligatorios y necesarios para continuar con el formulario.
      </FormParagraph>
      <SummitButton
        onClick={() => {
          if (props.passToForm4) {
            props.summit()
          }
        }}
      >
        SIGUIENTE
      </SummitButton>
      {/* <SummitButton
        onClick={() => {
          props.summit()
        }}
      >
        SIGUIENTE
      </SummitButton> */}
        </React.Fragment>
    :
    <div
    style={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      marginTop: "0px",
      padding: "15%",
    }}
  >
    {" "}
    <svg
      version="1.1"
      id="L9"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBlackground="new 0 0 0 0"
      xmlSpace="preserve"
    >
      <path
        fill="#008991"
        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="1s"
          from="0 50 50"
          to="360 50 50"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </div>

  }
    </>
  )
}

export default FormStep3
